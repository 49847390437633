const descargarArchivo = (data, type) => {
  try {
    let fileName = ""; //nombre del archivo para guardar
    let fileContent = ""; //dato de cada uno de las consultas)

    // Definir el nombre del archivo y su contenido según el `type`
    switch (type) {
      case "new":
        fileName = "PADRON_NUEVOS.txt";
        fileContent = data // Procesa el contenido específico para este tipo
        break;
      case "modif":
        fileName = "PADRON_MODIFICACIONES.txt";
        fileContent = data 
        break;
      case "down":
        fileName = "PADRON_BAJAS.txt";
        fileContent = data
        break;
      default:
        fileName = "ARCHIVO_DEFAULT.txt";
        fileContent = "Contenido no definido para este tipo.";
        break;
    }

    // Crear un Blob con el contenido procesado
    const blob = new Blob([fileContent], { type: "text/plain" });
    const url = window.URL.createObjectURL(blob);

    // Crear un enlace temporal para forzar la descarga
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName; // Nombre del archivo basado en el tipo
    document.body.appendChild(a);
    a.click();

    // Limpiar recursos
    a.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error al descargar el archivo:", error);
  }
};


export default descargarArchivo;
