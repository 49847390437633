import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUsers,
  search_User_detalle,
  search_user_facetec,
  getTypesUser,
  generatePDF,
} from "../../redux/actions";
import styles from "../../styles/Users.module.css";
import { Link } from "react-router-dom";
import Paginado from "./Paginado";
import Search from "./Search";
import { BiSearch } from "react-icons/bi";
import { IoReload } from "react-icons/io5";
import { Spiner } from "../Spiner"; // Suponiendo que tienes un componente Spinner
import Modal from "react-modal";
import DetalleUsuarios from "./DetalleUsuario";
import Movimientos from "./Movimientos";
import { GrTransaction } from "react-icons/gr";
import { CgMoreO } from "react-icons/cg";
import { FaArrowAltCircleDown } from "react-icons/fa";
import { PDFDownloadLink } from "@react-pdf/renderer";
import MyDocument from "../GeneradorPDF";
import Swal from "sweetalert2";
export const Usuarios = () => {
  const usuarios = useSelector((state) => state.users);
  const totalPage = useSelector((state) => state.totalUser);

  // Estados
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [showNoDataMessage, setShowNoDataMessage] = useState(false);
  const [estado, setEstado] = useState("Activo"); // Estado inicial
  const [modalDetailIsOpen, setIsDetailOpen] = useState(false);
  const [selectedIdUser, setSelectedIdUser] = useState(null);
  const [modalMovIsOpen, setIsMovOpen] = useState(false);
  const [selectedIdMov, setSelectedIdMov] = useState(null);
  const data = useSelector((state) => state.data_pdf);
  const [pdfLoadingMap, setPdfLoadingMap] = useState({}); 
  const dispatch = useDispatch();
  const [pdfReadyMap, setPdfReadyMap] = useState({});
  useEffect(() => {
    //console.log(data);
    dispatch(getTypesUser());
    const fetchData = async () => {
     // setLoading(true);
      try {
        await dispatch(getUsers(page, estado));
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, page, estado]); // Ahora también depende del estado seleccionado

  const handlePageChange = (newPage) => {
    setPage(newPage); // Cambiar la página
  };

  const handleReload = () => {
    setLoading(true);
    setShowNoDataMessage(false); // Ocultar mensaje de "no data" al recargar
    dispatch(getUsers(page, estado)).then(() => setLoading(false));
  };

  // Manejar el cambio de estado del select
  const handleEstadoChange = (e) => {
    setEstado(e.target.value); // Cambiar estado
    setPage(1); // Reiniciar la página a 1 cuando se cambie el estado
  };
  //*----modal detalle usuario-----
  const openModalUser = (id, dni) => {
    setSelectedIdUser(id);
    setIsDetailOpen(true);
    dispatch(search_User_detalle({ _id: id, page: 1 }));
    dispatch(search_user_facetec({ dni: dni, hide_images: false }));
  };

  const closeModalUser = () => {
    setIsDetailOpen(false);
    setSelectedIdUser(null);
  };
  //*----modal movmientos usuario-----
  const openModalMov = (cuit) => {
    setSelectedIdMov(cuit);
    setIsMovOpen(true);
  };

  const closeModalMov = () => {
    setIsMovOpen(false);
    // console.log("cerrar modal")
    setSelectedIdMov(null);
  };
  const generarPDF = async (id, e) => {
    e.preventDefault();
  // Marcar que estamos generando el PDF para este usuario en particular
  setPdfLoadingMap((prev) => ({ ...prev, [id]: true }));
    const body = {
      _id: id,
      hide_images: true,
    };
    const res = await dispatch(generatePDF(body));
  
    // Verifica si la respuesta tiene un estado 200
    if (res.status === 200) {
      setPdfReadyMap((prev) => ({ ...prev, [id]: true }));
      // Marca que el PDF está listo solo para el usuario actual
      setPdfReadyMap((prevMap) => ({
        ...prevMap,
        [id]: true, // Solo el usuario con el ID correspondiente será actualizado
      }));
      
    } else if (res.status === 400) {
     

      console.log(res?.data?.msg); // Muestra el error si el estado es 400
      Swal.fire({
        title:"Error al generar PDF",
        icon:"warning"
      })
    }
      // Una vez completada la generación, desactivar el estado de carga
  setPdfLoadingMap((prev) => ({ ...prev, [id]: false }));

  };

  return (
    <div className="container">
      <div className=" d-flex align-items-center p-1">
        <IoReload
          title="Recargar"
          onClick={handleReload}
          style={{
            fontSize: "25px",
            color: "orange",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        />
        <h5
          style={{ margin: "10px", marginLeft: "20px" }}
          className="text-center"
        >
          Admin. de Usuarios
        </h5>
      </div>

      <Search setPagina={setPage} />

      <div className="col-md-6 col-lg-2 mb-2">
        <label htmlFor="estado-select">Estado:</label>
        <select
          id="estado-select"
          className={`form-select`}
          value={estado}
          onChange={handleEstadoChange}
        >
          <option value="" hidden>
            Seleccionar
          </option>

          <option value="Activo">Activos</option>
          <option value="Inactivo">Inactivos</option>
          <option value="Baja">Dados de baja</option>
          <option value="Rechazado">Rechazados</option>
        </select>
      </div>

      {loading ? (
        <Spiner /> // Mostrar Spinner mientras está cargando
      ) : usuarios && usuarios.length ? (
        <div>
          <table className={`table`}>
            <thead className="table-info">
              <tr>
                <th>Fecha de Registro</th>
                <th>Usuario</th>
                <th>Nombre y Apellido</th>
                <th>DNI</th>
                <th>CVU</th>
                <th>Saldo $</th>
                <th>Estado</th>
                <th>Ver Movimientos</th>
                <th>Ver Detalle</th>
                <th>Descargar Legajo (PDF)</th>
              </tr>
            </thead>
            <tbody>
              {usuarios.map((user, index) => (
                <tr key={index}>
                  <td> {new Date(user.dateCreated)?.toLocaleString()}</td>
                  <td>{user.user}</td>
                  <td>{`${user.name} ${user.lastName}`}</td>
                  <td>{user.dni}</td>
                  <td>{user.cvu || "Pendiente"}</td>
                  <td>
                    {parseFloat(
                      user.currentBalance?.toFixed(2)
                    )?.toLocaleString("es-ES", { minimumFractionDigits: 2 })}
                  </td>
                  <td>
                    {user.state === "Activo" ? <p>Activo </p> : <p>Inactivo</p>}
                  </td>
                  <td>
                    <button
                      className="btn p-1"
                      onClick={() => openModalMov(user?.cuit)}
                    >
                      <GrTransaction size={22} color="salmon" />
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn p-1"
                      onClick={() => openModalUser(user?._id, user?.dni)}
                    >
                      <CgMoreO size={22} />
                    </button>
                  </td>
                  <td>
                  <div>
                {/* Botón para generar el PDF */}
                <button
                className="btn btn-sm " style={{fontSize:12}}
                  onClick={(e) => generarPDF(user._id, e)}
                  disabled={pdfLoadingMap[user._id]  || pdfReadyMap[user._id]} // Deshabilitar solo si se está generando el PDF para este usuario
                >
                  {pdfLoadingMap[user._id]
                    ? "Generando PDF..."
                    : pdfReadyMap[user._id]
                    ? "PDF Generado"
                    : "Generar PDF"}
                </button>

                {/* Mostrar el enlace de descarga solo si el PDF está listo para este usuario */}
                {pdfReadyMap[user._id] && data && (
                  <PDFDownloadLink style={{marginLeft:5}}
                    document={<MyDocument data={data} />} // Pasa los datos cuando están disponibles
                    fileName={`Legajo-usuario-${user.name}-${user.lastName}.pdf`}
                  >
                    {({ loading: pdfLoading }) =>
                      pdfLoading ? "" : <FaArrowAltCircleDown size={22} />
                    }
                  </PDFDownloadLink>
                )}
              </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Paginado
            data={totalPage}
            pagina={page}
            setPagina={handlePageChange}
          />
        </div>
      ) : (
        <div className="alert alert-warning" role="alert">
          No se encontraron datos. <BiSearch style={{ fontSize: "30px" }} />
        </div>
      )}
      <Modal
        isOpen={modalMovIsOpen}
        className={styles.modal}
        overlayClassName={styles.Overlay} //fondo atras
        onRequestClose={closeModalMov}
        contentLabel="Movimientos"
      >
        <Movimientos
          cuit={selectedIdMov}
          type=""
          page={page}
          setPagina={setPage}
          closeModalMov={closeModalMov}
        />
      </Modal>

      {/* modal detalle usuario */}

      <Modal
        isOpen={modalDetailIsOpen}
        className={styles.modal}
        overlayClassName={styles.Overlay} //fondo atras
        onRequestClose={closeModalUser}
        contentLabel="Detalle Asociado"
      >
        <DetalleUsuarios
          id={selectedIdUser}
          pagina={page}
          setPagina={setPage}
          closeModalUser={closeModalUser}
        />
      </Modal>
    </div>
  );
};
