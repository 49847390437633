import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  search_User,
  search_by_validation,
  search_by_name,
  search_User_asociado,
  search_User_asociado_status,
  search_User_asociado_name,
  get_provinces,
} from "../../redux/actions";
import "../../styles/Navbar.module.css";

const Navbar = ({ setPagina, Asociados }) => {
  const [search, setSearch] = useState(""); // Estado inicializado
  const [name, setName] = useState(""); // Estado inicializado
  const [lastName, setLastName] = useState(""); // Estado inicializado
const [provinceList,setProvList]= useState([])
const [provSelected,setProvSelected]= useState("")
  const error = useSelector((state) => state.error);
  const [textBtn, setTextBtn] = useState("Buscar");
  const dispatch = useDispatch();

  useEffect(() => {
   // console.log(Asociados);
  getProv()
  }, [search, Asociados]);


  const getProv =async()=>{
   let res= await dispatch (get_provinces())
   res &&  setProvList(res?.msg) 
  // console.log(res)
  }
  const buscarUsuario = async (e) => {
    e.preventDefault();
    setTextBtn("Buscando..");
  
    try {
      const params = {};
  
      // Agregar solo los parámetros válidos
      if (provSelected) params.province = provSelected;
      if (search) params.dni = parseInt(search);
      if (name) params.name = name
      if (lastName) params.lastName = lastName
  
      // Página siempre debe estar presente
      params.page = 1;
  
      if (Object.keys(params).length <= 1) {
        // Si solo tiene "page" y no hay criterios de búsqueda
        alert("Por favor, complete al menos un criterio de búsqueda.");
        setTextBtn("Buscar");
        return;
      }
  
      dispatch(search_User(params));
      setPagina(1);
    } catch (error) {
      console.error(error.message);
    } finally {
      setTextBtn("Buscar");
    }
  };
  
  
  const buscarUsuarioAsociado = (e) => {
    //console.log("entro aca");
    e.preventDefault();
    setTextBtn("Buscando..");
    try {
      if (search && Asociados) {
        dispatch(search_User_asociado(parseInt(search)));
        setPagina(1);
      } 
       else if (name  && lastName && Asociados) {
        const body = {
          name: name?.toUpperCase(),
          lastName: lastName?.toUpperCase(),
          page: 1,
        };
        dispatch(search_User_asociado_name(body));
        setPagina(1);

      }

   
    } catch (error) {
      // console.log(error.message);
    } finally {
      setTextBtn("Buscar");
    }
  };

  return (
    <nav className="containerNav">
      <div
        className={`row pt-2`}
        role="search"
      
      >
        <div className="col-md-6 col-lg-2">
          <label htmlFor="dni" style={{ marginRight: "5px", fontSize: "14px" }}>
            DNI:
          </label>
          <input
            className={`form-control `}
            type="number"
            min={0}
            placeholder=""
            aria-label="Search"
            value={search}
            name="search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="col-md-6 col-lg-2">
          <label htmlFor="label">Nombres:</label>
          <input
            className={`form-control`}
            value={name}
            name="name"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="col-md-6 col-lg-2">
          <label htmlFor="label">Apellidos:</label>
          <input
            className={`form-control`}
            value={lastName}
            name="lastName"
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <div className="col-md-4 col-lg-2" hidden={Asociados} >
        <label htmlFor="label">Provincia:</label>

          <select className="form-control" value={provSelected} onChange={(e)=> setProvSelected(e.target.value)}>
            {
              provinceList?.map((el,i)=>(
                <>
              

                <option key={i}>{el.province}</option>
                
                </>

              ))
            }
          </select>
        </div>
      
        <div className="col-lg-2 d-flex justify-content-center">
          <button
              onClick={(e) => {
                if (Asociados) {
                  buscarUsuarioAsociado(e); // Llama a la función correcta si hay Asociados
                } else {
                  buscarUsuario(e);
                }
              }}
            style={{
              border: "solid 1px var(--naranja)",
              margin: "20px",
              fontSize: "14px",
            }}
            className=" btn btn-outline btn-lg"
            type="submit"
          >
            {textBtn}
          </button>
        </div>
      </div>
      <hr className={"dottedHr"}></hr>
    </nav>
  );
};

export default Navbar;
