import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { BCRAFunction,BCRA_down,BCRA_modif } from "../../redux/actions";
import Swal from "sweetalert2";
import { CiSaveDown2 } from "react-icons/ci";
import descargarArchivo from "../../helpers/downloadFile";
const DescargarArchivo = () => {
  const [desde, setDesde] = useState("");
  const [hasta, setHasta] = useState("");
  const [btnDownload,setBtnDowload]= useState(false)
  const [data,setData]= useState("")
  const [dataDown,setDataDown]= useState("")
  const [dataModif,setDataModif]= useState("")
  const [textBtn,setTextBtn]= useState("Consultar")
  const [btnBlok,setBtnblok]= useState(false)
  const dispatch = useDispatch();
// Obtener la fecha actual en formato YYYY-MM-DD
  const today = new Date().toISOString().split("T")[0];

  const consultaBcra = async(action,type) => {
   // console.log(type)
    try {
      setTextBtn("Cargando...")
      setBtnblok(true)
      const body = {
        inicio: desde,
        fin: hasta,
      };
      const response = await dispatch(action(body))
 
      if( response.status===200){
        if(type==="new"){
          setData(response.data)
        }
        if(type==="down"){
          setDataDown(response.data)
        }else{
          setDataModif(response.data)
    
        }
        setBtnDowload(true)
      }else{
        Swal.fire("Ups,","Ocurrió un error al realizar la consulta ","error")
      }
    
    } catch (error) {
      console.log(error)
    }finally{
      setTextBtn("Consultar")
      setBtnblok(false)
   
    }
  }
  return (
    <div className="container-sm">
      <h6 className="ms-3">Seleccionar fechas</h6>
      <div className="d-flex align-items-center">
        <label htmlFor="">Desde</label>
        <input
          className="form-control"
          type="date"
          value={desde}
          max={today}
          style={{ width: 300 }}
          onChange={(e) => setDesde(e.target.value)}
        />
        <label htmlFor="">Hasta</label>
        <input
          className="form-control"
          type="date"
          max={today}
          value={hasta}
          style={{ width: 300 }}
          onChange={(e) => setHasta(e.target.value)}
        />
      <button disabled={btnBlok} className="btn btn-secondary ms-2"  onClick={async () => {
        await consultaBcra(BCRAFunction,"new");
        await consultaBcra(BCRA_down,"down");
        await consultaBcra(BCRA_modif, "modif");
  
  }}>{textBtn}</button>
      </div>
      <div className="card p-4 m-3" hidden={!btnDownload}>
       <button 
         onClick={async () => {
          await descargarArchivo(data,"new");
          await descargarArchivo(dataDown,"down");
          await descargarArchivo(dataModif,"modif");
         }}    
       className="btn py-2"><CiSaveDown2 className="text-success" size={20}/> Descargar archivos</button>
      </div>
    </div>
  );
};

export default DescargarArchivo;
